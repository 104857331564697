import { Component, OnInit } from "@angular/core";
import { PostService } from "src/app/_services/post.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-activity-section",
    templateUrl: "./activity-section.component.html",
    styleUrls: ["./activity-section.component.scss"],
})
export class ActivitySectionComponent implements OnInit {
    posts: any;
    constructor(private _postService: PostService) {}
    baseUrlHub = environment.API_URL_HUB;

    ngOnInit(): void {
        this.getLatestActivities();
    }

    // for tab click event
    currentTab = "tab1";
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }

    getLatestActivities() {
      // 7 is categoryType.ACTIVITY
        this._postService.getForHomePage(7).subscribe((res) => {
            this.posts = res.response;
        });
    }
}
