<!-- <div class="countdown-area ptb-50">
    <div class="container">
        <div class="row align-items-center text-center">
            <div class="col-lg-3 col-md-12">
                <div class="countdown-content">
                    <h2>151</h2>
                    <h5>برنامجاً أكاديمياً</h5>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="countdown-content">
                    <h2>166</h2>
                    <h5>شريكاً خارجياً</h5>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="countdown-content">
                    <h2>89</h2>
                    <h5>اتفاقية تعاون مشترك</h5>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="countdown-content">
                    <h2>33</h2>
                    <h5>مركزاً علمياً وبحثياً</h5>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="ads-area ptb-100" [style.background-image]="backgroundImage">
    <div class="container">
        <div class="ads-content">
            <div class="section-title">
                الإعلانات
            </div>
            <div class="ads-slides">
                <owl-carousel-o [options]="adsSlides">
                    <ng-container *ngFor="let ad of ads">
                        <ng-container *ngIf="ad.imageString">
                            <ng-template carouselSlide>
                                <h3>{{ ad.title }}</h3>
                                <p>وظيفة محاضر في المراد البرمجة</p>
                                <a routerLink="/" class="default-btn"><i
                                        class="bx bx-book-reader icon-arrow before"></i><span class="label">تقديم الى
                                        الوظيفة</span><i class="bx bx-book-reader icon-arrow after"></i></a>
                            </ng-template>
                        </ng-container>
                        <ng-container *ngIf="!ad.imageString">
                            <ng-template carouselSlide>
                                <h3>{{ ad.title }}</h3>
                                <p>وظيفة محاضر في المراد البرمجة</p>
                                <a routerLink="/" class="default-btn"><i
                                        class="bx bx-book-reader icon-arrow before"></i><span class="label">تقديم الى
                                        الوظيفة</span><i class="bx bx-book-reader icon-arrow after"></i></a>
                            </ng-template>
                        </ng-container>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>