import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Websetting } from "src/app/_models/webSetting";
import { PostService } from "src/app/_services/post.service";
import { WebsettingService } from "src/app/_services/websetting.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-all-news",
    templateUrl: "./all-news.component.html",
    styleUrls: ["./all-news.component.scss"],
})
export class AllNewsComponent implements OnInit {
    response: any;
    isAct = false;
    backgroundImage: SafeStyle;
    path = environment.API_URL_HUB;
    baseImage = "page-title1.jpg";
    coverPhoto: SafeStyle;
    currentPage: number = 1;
    itemsPerPage: number = 12;
    totalItems: number;
    subtitle = '';
    categoryType = 1;
    constructor(
        private _websettingService: WebsettingService,
        private _activatedRoute: ActivatedRoute,
        private _sanitizer: DomSanitizer,
        private _postService: PostService
    ) {}

    ngOnInit(): void {
        this._activatedRoute.url.subscribe(url => {
            if (url.some(segment => segment.path.includes('activities'))) {
                this.isAct = true;
                this.categoryType = 7;
              }
        });

        if(this.isAct){
            this.coverPhoto = this._websettingService.webSetting()
            ?.activitiesHeroImage
            ? this._sanitizer.bypassSecurityTrustStyle(
                  `url(${this.path}${this._websettingService?.webSetting()?.activitiesHeroImage})`
              )
            : this._sanitizer.bypassSecurityTrustStyle(
                  "url(assets/img/page-title/page-title1.jpg)"
              );
        }else{
            this.coverPhoto = this._websettingService.webSetting()
            ?.postHeroImage
            ? this._sanitizer.bypassSecurityTrustStyle(
                  `url(${this.path}${this._websettingService?.webSetting()?.postHeroImage})`
              )
            : this._sanitizer.bypassSecurityTrustStyle(
                  "url(assets/img/page-title/page-title1.jpg)"
              );
        }
       
              this.subtitle = this.isAct?'الأنشطة و الفعاليات': 'الأخبار';
        this.getPosts();
    }

    getPosts() {
        this._postService
            .getAll(this.currentPage, this.itemsPerPage, null,this.categoryType)
            .subscribe({
                next: (res) => {
                    this.response = res.response;
                    this.totalItems = res.response.pagination.rowCount;
                },
            });
    }

    onTableDataChange(event: any) {
        this.currentPage = event;
        this.getPosts();
    }
    onTableSizeChange(event: any): void {
        this.itemsPerPage = event.target.value;
        this.getPosts();
    }
}
