<app-header />

<div *ngIf="$post | async as post">
    <div class="page-title-area" [style.background-image]="backgroundImage">
        <div class="container">
            <div class="page-title-content">
                <ul>
                    <li><a routerLink="/">الرئيسية</a></li>
                    <li>
                        <a [routerLink]="isAct ? '/activities' : '/news'">{{
                            subtitle
                        }}</a>
                    </li>
                    <li>{{ post.response.title }}</li>
                </ul>
                <h2>{{ subtitle }}</h2>
            </div>
        </div>
    </div>

    <div class="courses-details-area pt-100 pb-70">
        <div class="container">
            <div class="courses-details-header">
                <div class="row align-items-center">
                    <div class="col-lg-8">
                        <div class="courses-title">
                            <h2>{{ post?.response.title }}</h2>
                            <p *ngIf="post.subTitle">
                                {{ post?.response.subTitle }}
                            </p>
                        </div>
                        <div class="courses-meta">
                            <ul>
                                <li>
                                    <i class="bx bx-folder-open"></i>
                                    <span>{{
                                        post?.response.category?.title
                                    }}</span>
                                    <a routerLink="/">التصنيف</a>
                                </li>
                            </ul>
                            <div class="events-area pt-100 pb-70">
                                <div class="container">
                                    <div class="single-events-box mb-30">
                                        <div
                                            [innerHTML]="post?.response.body"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-lg-12">
                    <div class="courses-details-image text-center">
                        <img [src]="path + post.imageString" alt="image">
                    </div>
                    <div class="mt-3" [innerHTML]="post.description"></div>
                    <div class="related-courses mt-3">
                        <h3>أخبار ذات صلة</h3>
                        <div *ngIf=" $posts | async as posts else loading" class="row">
                            <div class="col-lg-6 col-md-6" *ngFor="let current of posts">
                                <div class="single-courses-box mb-30">
                                    <div class="courses-image">
                                        <a [routerLink]="'/news/'+ current.id" class="d-block"><img [src]="path + current.imageString" alt="image"></a>
                                        <div class="courses-tag">
                                            <a routerLink="/" class="d-block">{{current.category.title}}</a>
                                        </div>
                                    </div>
                                    <div class="courses-content">
                                        <h3><a [routerLink]="'/news/'+ current.id" class="d-inline-block">{{current.title}}</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>

<ng-container #loading> </ng-container>
