export const environment = {
    production: false,
    API_URL: "https://mcpapi.israa.edu.ps/api/",
    API_URL_NEW: 'https://localhost:44380/api/app/',
    API_URL_HUB: "https://mcpapi.israa.edu.ps/",
    API_URL_HUB_NEW: 'https://localhost:44380/api/app/',
    imageEndPoint: {
        normal: "https://mcpapi.israa.edu.ps/images/",
        med: "https://mcpapi.israa.edu.ps/images/Thumbs/med/",
        small: "https://mcpapi.israa.edu.ps/images/Thumbs/small/",
    },
    imageEndPointNew: 'https://localhost:44380'
    
  ,
};
