<div class='row'>

    <div class='col-lg-4 col-md-6 col-sm-6' *ngFor='let gallery of galleries'>
      <!-- <h4 class="text-center">ألبومات الصور</h4> -->
      <div class='single-instructor-member mb-30'>
        <div class='member-image'>
          <img [src]='baseUrlHub+gallery?.imageString' alt='images'>
        </div>
        <div class='member-content'>
          <h3><a>{{gallery?.description}}</a></h3>
          <span>{{gallery?.category?.title}}</span>
          <ul class="social">
            <li><a (click)="navigateTOGallery(gallery.id)">مشاهدة الالبوم</a></li>
            <li><a routerLink="/galleries">جميع الألبومات</a></li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <div class='col-lg-4 col-md-6 col-sm-6'>
      <h4 class="text-center">{{'::VediosPlaylist' | abpLocalization}}</h4>
      <div class='single-instructor-member mb-30'>
        <div class='member-image'>
          <img [src]='playlist?.imageString' alt='images'>
        </div>
        <div class='member-content'>
          <h3><a>{{playlist?.title}}</a></h3>
          <span>{{playlist?.category?.title}}</span>
          <ul class="social">
            <li><a (click)="navigateTOGallery(playlist.id)">مشاهدة الالبوم</a></li>
            <li><a (click)="navigateTOPlaylists(playlist.id)">جميع الألبومات</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class='col-lg-4 col-md-6 col-sm-6'>
      <h4 class="text-center">{{'::Activities' | abpLocalization}}</h4>
      <div class='single-instructor-member mb-30'>
        <div class='member-image'>
          <img [src]='activity?.imageString' alt='images'>
        </div>
        <div class='member-content'>
          <h3><a>{{activity?.title}}</a></h3>
          <span>{{activity?.category?.title}}</span>
          <ul class="social">
            <li><a (click)="navigateTOActivites(activity.id)">مشاهدة الفعالية</a></li>
            <li><a [routerLink]="'/news/type/'+typeAct">جميع الأنشطة</a></li>
          </ul>
        </div>
      </div>
    </div> -->
  </div>