import { Component, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
    selector: "app-categories-style-one",
    templateUrl: "./categories-style-one.component.html",
    styleUrls: ["./categories-style-one.component.scss"],
})
export class CategoriesStyleOneComponent implements OnInit {
    links = {
        unifiedSystem:'https://nsystem.israa.edu.ps/account/login',
        studentServices:'https://student.israa.edu.ps',
        lecturerPortal:'https://instructor.israa.edu.ps',
        electronicExams:'https://elearn.israa.edu.ps',
        eLearning:'https://elearning.israa.edu.ps/Login.aspx',
        recruitmentPortal:'https://jobs.israa.edu.ps/Login/',

    }
    constructor() {}

    ngOnInit(): void {}
}
