import { Component, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { SliderService } from "src/app/_services/slider.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-hometwo-main-banner",
    templateUrl: "./hometwo-main-banner.component.html",
    styleUrls: ["./hometwo-main-banner.component.scss"],
})
export class HometwoMainBannerComponent implements OnInit {
    sliders: any;
    baseUrlHub = environment.API_URL_HUB;
    constructor(private _sliderService: SliderService) {}

    ngOnInit(): void {
        this.getAllSlider();
    }

    homeSlides: OwlOptions = {
        loop: false,
        nav: true,
        dots: true,
        autoplayHoverPause: true,
        autoplay: true,
        rtl: true,
        items: 1,
        autoHeight: true,
        navText: [
            "<i class='bx bx-chevron-right'></i>",
            "<i class='bx bx-chevron-left'></i>",
        ],
    };

    getAllSlider() {
        this._sliderService.getForHomePage().subscribe((res) => {
            this.sliders = res;
        });
    }
}
