import { Component, inject, OnInit } from "@angular/core";
import { AboutUsService } from "src/app/_services/aboutUs.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-about-us",
    templateUrl: "./about-us-page.component.html",
    styleUrls: ["./about-us-page.component.scss"],
})
export class AboutUsPageComponent implements OnInit {
    firstPartOfPathForImages = environment.imageEndPointNew;

    public aboutUsService = inject(AboutUsService);

    ngOnInit(): void {
    }
}
