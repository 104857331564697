import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class PostService {
    baseUrl = environment.API_URL + "posts/";
    constructor(private _http: HttpClient) {}

    getForHomePage(categoryType: number | null): Observable<any> {
        let query = "?";
        if (categoryType) query += `categoryType=${categoryType}`;
        return this._http.get<any>(this.baseUrl + "GetForHomePage" + query);
    }

    get(id: number): Observable<any> {
        return this._http.get<any>(this.baseUrl + "GetForClient/" + id);
    }

    getAll(
        page: number | null = null,
        pageSize: number | null = null,
        search: string | null = null,
        categoryType: number | null = null
    ): Observable<any> {
        let query = "?";
        if (page) query += `&page=${page}`;
        if (pageSize) query += `&pageSize=${pageSize}`;
        if (search) query += `&search=${search}`;
        if (categoryType) query += `&categoryType=${categoryType}`;
        return this._http.get<any>(this.baseUrl + "GetAllForClient" + query);
    }
}
