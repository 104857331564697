<app-header />

<div
    class="page-title-area page-title-style-two"
    [style.background-image]="coverPhoto"
>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">الرئيسية</a></li>
                <li>
                    <a [routerLink]="isAct ? '/activities/' : '/news/'">{{
                        subtitle
                    }}</a>
                </li>
            </ul>
            <h2>{{ subtitle }}</h2>
        </div>
    </div>
</div>

<div class="courses-area ptb-100">
    <div class="container">
        <div class="courses-topbar">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4">
                    <div class="topbar-result-count">
                        <p>
                            تم عرض {{ response?.pagination?.pageSize }} من أصل
                            {{ response?.pagination?.rowCount }}
                        </p>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="topbar-ordering-and-search">
                        <div class="row align-items-center">
                            <div
                                class="col-lg-3 col-md-5 offset-lg-4 offset-md-1 col-sm-6"
                            >
                                <div class="topbar-ordering"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div
                *ngFor="
                    let post of response?.data
                        | paginate
                            : {
                                  itemsPerPage: itemsPerPage,
                                  currentPage: currentPage,
                                  totalItems: totalItems
                              }
                "
                class="col-lg-4 col-md-6"
            >
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a [routerLink]="'/news/' + post.id" class="d-block">
                            <img
                                class="width-img"
                                [src]="path + post.img"
                                alt="image"
                            />
                        </a>
                        <div class="courses-tag" *ngIf="post.category">
                            <a routerLink="/" class="d-block">{{
                                post.category
                            }}</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <h3>
                            <a
                                [routerLink]="'/news/' + post.id"
                                style="
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                "
                                >{{ post.title }}</a
                            >
                        </h3>
                    </div>
                    <div class="courses-box-footer"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area text-center">
                    <pagination-controls
                        previousLabel=""
                        nextLabel=""
                        (pageChange)="onTableDataChange($event)"
                    >
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>
