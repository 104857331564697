import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { NavigationExtras, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { GalleryService } from 'src/app/_services/gallery.service';
import { WebsettingService } from 'src/app/_services/websetting.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-instructors-page-one',
  templateUrl: './instructors-page-one.component.html',
  styleUrls: ['./instructors-page-one.component.scss']
})
export class InstructorsPageOneComponent implements OnInit {
  galleries: any[] = [];
  baseUrl = environment.API_URL_HUB;
  coverImage: SafeStyle;

  constructor(
    private _galleryService: GalleryService,
    private _router: Router,
    private _sanitizer: DomSanitizer,
    private _webSettingService: WebsettingService
  ) {
    const navigation = this._router.getCurrentNavigation();

  
    
        this.coverImage = this._webSettingService.webSetting()?.albumImageHeroImage
        ? this._sanitizer.bypassSecurityTrustStyle(`url(${this.baseUrl}${this._webSettingService.webSetting()?.albumImageHeroImage})`)
        : this._sanitizer.bypassSecurityTrustStyle('url(assets/img/page-title/page-title1.jpg)');
   
  }

  ngOnInit(): void {
    this._galleryService
      .getListByCategoryTypeByCategoryType(3)
      .subscribe({
        next: res => {
          this.galleries = res.response;
        },
      });
  }

  navigateTOGallery(id: number) {
    this._galleryService.get(id).subscribe(res => {
      const navigationExtras: NavigationExtras = { state: res };
      this._router.navigate(['galleries/', id], navigationExtras);
    });
  }
}
