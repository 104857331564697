import { Component, ElementRef, OnInit, Renderer2, OnDestroy } from "@angular/core";
import { ColorService } from "src/app/_services/color.service";
import { Subscription } from "rxjs";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit, OnDestroy {
    private colorSubscription!: Subscription;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private colorService: ColorService
    ) {}

    ngOnInit(): void {
        this.colorSubscription = this.colorService.color$.subscribe(color => {
            this.changeFooterColor(color);
        });
    }

    changeFooterColor(color: string) {
        const element = this.el.nativeElement.querySelector(".footer-area");
        const element2 = this.el.nativeElement.querySelector(".footer-bottom-area");

        if (element && element2) {
            this.renderer.setStyle(element, "background-color", color);
            this.renderer.setStyle(element2, "background-color", color);
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe to avoid memory leaks
        if (this.colorSubscription) {
            this.colorSubscription.unsubscribe();
        }
    }
}
