<app-header />

<div class="page-title-area item-bg4">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Events</li>
            </ul>
            <h2>Events</h2>
        </div>
    </div>
</div>

<div class="events-area pt-100 pb-70">
    <div class="container">
        <div class="single-events-box mb-30">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg1">
                        <img src="assets/img/events/events1.jpg" alt="image" />
                    </div>
                </div>
                <div class="events-content">
                    <div class="content">
                        <h3>
                            <a routerLink="/single-events"
                                >NZ Education Fair 2020 in USA</a
                            >
                        </h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                        </p>
                        <span class="location"
                            ><i class="bx bx-map"></i> 120 G-35 Spinsovila Sc,
                            USA</span
                        >
                        <a routerLink="/single-events" class="join-now-btn"
                            >Join Now</a
                        >
                    </div>
                </div>
                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>01</span>
                                <h3>23 October</h3>
                                <p>7:30 PM</p>
                                <i class="bx bx-calendar"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="single-events-box mb-30">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg2">
                        <img src="assets/img/events/events2.jpg" alt="image" />
                    </div>
                </div>
                <div class="events-content">
                    <div class="content">
                        <h3>
                            <a routerLink="/single-events"
                                >UK Education Fair USA</a
                            >
                        </h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                        </p>
                        <span class="location"
                            ><i class="bx bx-map"></i> 120 G-35 Spinsovila Sc,
                            USA</span
                        >
                        <a routerLink="/single-events" class="join-now-btn"
                            >Join Now</a
                        >
                    </div>
                </div>
                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>02</span>
                                <h3>24 October</h3>
                                <p>8:30 PM</p>
                                <i class="bx bx-calendar"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="single-events-box mb-30">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg3">
                        <img src="assets/img/events/events3.jpg" alt="image" />
                    </div>
                </div>
                <div class="events-content">
                    <div class="content">
                        <h3>
                            <a routerLink="/single-events"
                                >UK Education Expo 2020</a
                            >
                        </h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                        </p>
                        <span class="location"
                            ><i class="bx bx-map"></i> 120 G-35 Spinsovila Sc,
                            USA</span
                        >
                        <a routerLink="/single-events" class="join-now-btn"
                            >Join Now</a
                        >
                    </div>
                </div>
                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>03</span>
                                <h3>25 October</h3>
                                <p>11:30 PM</p>
                                <i class="bx bx-calendar"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="single-events-box mb-30">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg4">
                        <img src="assets/img/events/events4.jpg" alt="image" />
                    </div>
                </div>
                <div class="events-content">
                    <div class="content">
                        <h3>
                            <a routerLink="/single-events"
                                >International English Conference</a
                            >
                        </h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                        </p>
                        <span class="location"
                            ><i class="bx bx-map"></i> 120 G-35 Spinsovila Sc,
                            USA</span
                        >
                        <a routerLink="/single-events" class="join-now-btn"
                            >Join Now</a
                        >
                    </div>
                </div>
                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>04</span>
                                <h3>26 October</h3>
                                <p>10:30 PM</p>
                                <i class="bx bx-calendar"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
