import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { PostService } from "src/app/_services/post.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-courses-details-page",
    templateUrl: "./courses-details-page.component.html",
    styleUrls: ["./courses-details-page.component.scss"],
})
export class CoursesDetailsPageComponent implements OnInit {
    $post: Observable<any>;
    $posts: Observable<any[]>;
    isAct = false;
    categoryType = 0;
    path = environment.API_URL_HUB;
    backgroundImage: SafeStyle;
    subtitle = '';

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _postService: PostService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        this._activatedRoute.paramMap.subscribe({
            next: (query) => {
                if (query.get("id")) {
                    this.$post = this._postService.get(+query.get("id")).pipe(
                        tap({
                            next: (res) => {
                                this.isAct = res.response.categoryType == 7;
                                this.subtitle = res.response.categoryType == 7?'الأنشطة و الفعاليات': 'الأخبار';
                                this.categoryType = res.categoryType;
                                this.backgroundImage = this.sanitizer.bypassSecurityTrustStyle(
                                  `url(${this.path}${res.response.img})`
                                );
                                //  this.$posts = this._postService.getPostByCategoryIdByCategoryIdAndCurrentPostId(res.categoryId, res.id);
                            },
                        })
                    );
                }
            },
        });
    }
}
