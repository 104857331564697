import { Component, OnInit } from '@angular/core';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ColorService } from 'src/app/_services/color.service';
import { PageService } from 'src/app/_services/page.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent {

  page: any;
  private sub: Subscription;
  baseUrl = environment.API_URL_HUB;
  postImage: string;
  loading = true;
  backgroundImage: SafeStyle;
  pageId: number;
  constructor(
    private route: ActivatedRoute,
    private _pageService: PageService,
    private sanitizer: DomSanitizer,
   
  ) {

  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this._pageService.get(+params['id']).subscribe({
        next: (res: any) => {
          this.page = res;
          this.backgroundImage = this.sanitizer.bypassSecurityTrustStyle(
            `url(${this.baseUrl}${this.page.response.img})`
          );
          this.loading = false;
        },
      });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe(); 
  }
}
