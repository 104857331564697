import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomePageTwoComponent } from "./components/pages/home-page-two/home-page-two.component";
import { InstructorsPageOneComponent } from "./components/pages/instructors-page-one/instructors-page-one.component";
import { EventsPageComponent } from "./components/pages/events-page/events-page.component";
import { EventsDetailsPageComponent } from "./components/pages/events-details-page/events-details-page.component";
import { GalleryPageComponent } from "./components/pages/gallery-page/gallery-page.component";
import { ErrorPageComponent } from "./components/pages/error-page/error-page.component";
import { CoursesDetailsPageComponent } from "./components/pages/courses-details-page/courses-details-page.component";
import { AllNewsComponent } from "./components/pages/all-news/all-news.component";
import { PagesComponent } from "./components/pages/pages/pages.component";
import { ElectronicServicesComponent } from "./components/pages/electronic-services/electronic-services.component";
import { AboutUsPageComponent } from "./components/pages/about-us-page/about-us-page.component";
import { PresidentSpeechPageComponent } from "./components/pages/president-speech-page/president-speech-page.component";

const routes: Routes = [
    { path: "", component: HomePageTwoComponent },
    { path: "galleries", component: InstructorsPageOneComponent },
    { path: "galleries/:id", component: GalleryPageComponent },
    { path: "news", component: AllNewsComponent },
    { path: "activities", component: AllNewsComponent },
    { path: "activities/:id", component: CoursesDetailsPageComponent },
    { path: "news/:id", component: CoursesDetailsPageComponent },
    { path: "electronicServices", component: ElectronicServicesComponent },

    { path: "error-404", component: ErrorPageComponent },
    { path: "pages/:id", component: PagesComponent },
    { path: "events", component: EventsPageComponent },
    { path: "single-events", component: EventsDetailsPageComponent },
    { path: "about-us", component: AboutUsPageComponent },
    { path: "president-speech", component: PresidentSpeechPageComponent },
    {
        path: "faculty",
        loadChildren: () =>
            import("./components/faculties/faculties.module").then(
                (m) => m.FacultiesModule
            ),
    },
    { path: "**", component: ErrorPageComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
