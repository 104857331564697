import { Component, OnInit } from '@angular/core';
import { WebsettingService } from 'src/app/_services/websetting.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-hometwo-about',
  templateUrl: './hometwo-about.component.html',
  styleUrls: ['./hometwo-about.component.scss']
})
export class HometwoAboutComponent implements OnInit {
  baseUrlHub = environment. API_URL_HUB;
  
  constructor(public websettingService:WebsettingService) { }

  ngOnInit(): void {
  }

}
