<div class="partner-slides">
    <owl-carousel-o [options]="partnerSlides">
        <ng-template carouselSlide *ngFor='let partner of partners?.response'>
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img [src]="baseUrlHub + partner.imageString" alt="image">
                </a>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>