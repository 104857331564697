import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ColorService {
    private colorSubject = new BehaviorSubject<string>("#21669e"); // Default color
    color$ = this.colorSubject.asObservable(); // Observable that components can subscribe to

    changeColor(newColor: string) {
        this.colorSubject.next(newColor); // Emit the new color
    }

    getCurrentColor(): string {
        return this.colorSubject.getValue(); // Get the current color value
    }
}
