import { Component, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
    selector: "app-certificate",
    templateUrl: "./certificate.component.html",
    styleUrls: ["./certificate.component.scss"],
})
export class CertificateComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    instructorSlides: OwlOptions = {
        loop: false,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        rtl: true,
        autoplay: true,
        margin: 30,
        navText: [
            "<i class='bx bx-right-arrow-alt'></i>",
            "<i class='bx bx-left-arrow-alt'></i>",
        ],
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 2,
            },
            768: {
                items: 2,
            },
            1200: {
                items: 4,
            },
        },
    };
}
