import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Websetting } from "../_models/webSetting";
import { toSignal } from "@angular/core/rxjs-interop";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class WebsettingService {
  
    baseUrl = environment.API_URL + "WebSetting/";
    constructor(private _http: HttpClient) {}

    get(): Observable<any> {
        return this._http.get<any>(this.baseUrl);
    }

    webSetting = toSignal(
        this.get()
            .pipe(map((res) => res.response.data[0]))
    );
}
