import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class BusyService {

  bustRequestCount = 0;
  private spinnerTimeout: any;

  constructor(private spinnerService: NgxSpinnerService) {}

  busy() {
    this.bustRequestCount++;

    // Delay the spinner by 300ms to prevent it from showing for short requests
    this.spinnerTimeout = setTimeout(() => {
      this.spinnerService.show(undefined, {
        type :"ball-pulse",
        bdColor: 'rgba(255, 255, 255, .9)',
        color: '#279CE9',
        size: 'medium',
        
      });
    }, 300); // Delay for 300ms
  }

  idle() {
    this.bustRequestCount--;
    
    // If all requests have completed, hide the spinner
    if (this.bustRequestCount <= 0) {
      this.bustRequestCount = 0;
      clearTimeout(this.spinnerTimeout); // Clear the timeout if spinner hasn't shown yet
      this.spinnerService.hide(); // Hide the spinner
    }
  }
}
