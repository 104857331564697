<app-header />

<div
    class="page-title-area"
    [ngStyle]="{
        'background-image':
            'linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,0.8)), url(assets/israaImg/about-banner.png'
    }"
    [class.bg]="true"
>
    <div class="container">
        <div class="page-title-content">
            >
            <ul>
                <li><a routerLink="/">الرئيسية</a></li>
                <li>كلمة رئيس الجامعة</li>
            </ul>
            <h2>كلمة رئيس الجامعة</h2>
        </div>
    </div>
</div>

<div class="new-comers-area ptb-50">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="new-comers-image d-flex justify-content-center">
                    <img src="assets/israaImg/MLwgE.jpeg" alt="image" />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="new-comers-area pt-50">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="new-comers-content pb-100">
                    <h3 class="pb-10">
                        الحمد لله الذي علم بالقلم والصلاة والسلام على رسول
                        الإنسانية ومعلم البشرية سيدنا محمد وآله وصحبه وبعد،،،
                    </h3>
                    <br />
                    <h3>
                        انطلقت جامعة الإسراء نتاج التقاء إرادة مجلس الإدارة في
                        انشاء جامعة فلسطينية وطنية، سماتها التميز والإبداع،
                        فكانت الجامعة الأمل في تعليم عصري قائم على التطبيق
                        والتقنية والتكنولوجيا الحديثة المتطورة.
                    </h3>
                    <h3>
                        إن جامعة الإسراء هي جامعة لكل الفلسطينيين والباحثين،
                        وجهتها المهنية العالية والتفكير الحر المبدع، القائم على
                        المنهجية والعلمية.
                    </h3>
                    <h3>
                        تسعى جامعة الاسراء إلى تحقيق أهدافها من خلال التواصل
                        المستمر مع كافة الجهات الأكاديمية والتعليمية في الوطن
                        والخارج، من أجل الطالب الفلسطيني، وتقديم الأفضل له
                        دائما، وهي تنسق مع الجامعات الفلسطينية والعربية
                        والدولية، بغرض التعاون العلمي، والأكاديمي الذي يعزز
                        مكانة الطالب والمدرس والباحث، ويضيف جديدا لهم في مجال
                        العلم والمعرفة.
                    </h3>
                    <h3>
                        إن الدور الريادي لجامعة الإسراء يرتبط ارتباطا وثيقا
                        بكونها جزء من المجتمع الفلسطيني، وهو ما سيميز الجامعة في
                        العمل الجاد للبحث العلمي المعمق في المشكلات التي تواجه
                        المجتمع الفلسطيني، ومن ثم تقديم الاقتراحات والحلول لهذه
                        المشكلات مما يسهم في تخفيف معاناة المواطن الفلسطيني.
                    </h3>
                    <h3>
                        إن التميز الذي نسعى اليه في جامعة الإسراء يجعلنا نسخر كل
                        الجهود والخبرات لتخريج جيل جديد من القادة والمبدعين في
                        مختلف مجالات المعرفة لتكون الجامعة قوة للتطوير والرقي.
                    </h3>
                    <h3>
                        لقد وفرت الجامعة بيئة تعليمية متكاملة واستقطبت نخبة
                        مميزة من الأساتذة المؤهلين، ووفرت الخطط الدراسية المعدة
                        إعداداً دقيقاً من خبراء مختصين، ومنهاجاً مدروساً يربط
                        بين الدراسة النظرية والتطبيق، قادراً على احتضان طالب
                        طموح، وتخريج قيادي مؤهل، للتنافس واثبات الذات وطنياً
                        وعالمياً.
                    </h3>
                    <h3>
                        شعارنا أن نحمل الأمانة ونعمل معا بهدف رقي الجامعة
                        وتقدمها، وإكمال مسيرة الجامعة ووضعها في مكانها المأمول
                        والمنشود كجامعة وطنية فلسطينية متميزة ومنارة للعلم
                        والمعرفة.
                    </h3>
                    <br />
                    <h2>ق.أ رئيس الجامعة</h2>
                    <h2>د. علاء محمد مطر</h2>
                </div>
            </div>
        </div>
    </div>
</div>
