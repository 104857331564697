<header class="header-area">
    <div class="navbar-area navbar-style-two" [ngClass]="{'sticky': isSticky}">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                <a class="navbar-brand" routerLink="/"><img src="assets/israaImg/HeaderLogo.png" alt="logo"></a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav" id="navbar">
                        <li class="nav-item" *ngFor="let header of mainTitleInHeader">
                            <a (click)="navigateTopage(header)" class="nav-link">{{ header.title
                                }}<i *ngIf="header.subLinks.length > 0" class="bx bx-chevron-down"></i></a>
                            <ul class="dropdown-menu" *ngIf="header.subLinks.length > 0">
                                <li class="nav-item" *ngFor="let sub1 of header.subLinks">
                                    <a (click)="navigateTopage(sub1)" class="nav-link">{{ sub1.title
                                        }}<i *ngIf="sub1.subLinks.length > 0" class="bx bx-chevron-left"></i></a>
                                    <ul class="dropdown-menu" *ngIf="sub1.subLinks.length > 0">
                                        <li class="nav-item" *ngFor="let sub2 of sub1.subLinks">
                                            <a (click)="navigateTopage(sub2)" class="nav-link">{{ sub2.title
                                                }}<i *ngIf="sub2.subLinks.length > 0"
                                                    class="bx bx-chevron-left"></i></a>
                                            <ul class="dropdown-menu" *ngIf="sub2.subLinks.length > 0">
                                                <li class="nav-item" *ngFor="let sub3 of sub2.subLinks">
                                                    <a (click)="navigateTopage(sub3)" class="nav-link">{{ sub3.title
                                                        }}<i *ngIf="sub3.subLinks.length > 0"
                                                            class="bx bx-chevron-left"></i></a>
                                                    <ul class="dropdown-menu" *ngIf="sub3.subLinks.length > 0">
                                                        <li class="nav-item" *ngFor="let sub4 of sub3.subLinks">
                                                            <a (click)="navigateTopage(sub4)" class="nav-link">{{
                                                                sub4.title
                                                                }}<i *ngIf="sub4.subLinks.length > 0"
                                                                    class="bx bx-chevron-left"></i></a>
                                                            <ul class="dropdown-menu" *ngIf="sub4.subLinks.length > 0">
                                                                <li class="nav-item" *ngFor="let sub5 of sub4.subLinks">
                                                                    <a (click)="navigateTopage(sub5)"
                                                                        class="nav-link">{{ sub5.title
                                                                        }}<i *ngIf="sub5.subLinks.length > 0"
                                                                            class="bx bx-chevron-left"></i></a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <!-- <div class="others-option">
                    <div class="dropdown language-switcher d-inline-block" [class.active]="classApplied2">
                        <button class="dropdown-toggle" type="button" (click)="toggleClass2()">
                            <img src="assets/img/us-flag.jpg" class="shadow" alt="image">
                            <span>Eng <i class='bx bx-chevron-down'></i></span>
                        </button>
                        <div class="dropdown-menu">
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/germany-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Ger</span>
                            </a>
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/france-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Fre</span>
                            </a>
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/spain-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Spa</span>
                            </a>
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/russia-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Rus</span>
                            </a>
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/italy-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Ita</span>
                            </a>
                        </div>
                    </div>
                    <div class="search-box d-inline-block">
                        <i class='bx bx-search' (click)="toggleClass3()"></i>
                    </div>
                </div> -->
            </nav>
        </div>
    </div>
</header>

<div class="search-overlay" [class.active]="classApplied3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>