<app-header />


<div class="page-title-area">
    <img [src]="firstPartOfPathForImages+aboutUsService.data().heroImage" alt="Background" class="background-image">
    <div class="page-title-content">
        <ul>
            <li><a routerLink="/">الرئيسية</a></li>
            <li>من نحن</li>
        </ul>
        <h2>{{aboutUsService.data().title}}</h2>
    </div>
</div>


<!-- <div class="page-title-area" [ngStyle]="{
        'background-image':
            'linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,0.8)), url(assets/israaImg/about-banner.png'
    }" [class.bg]="true">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">الرئيسية</a></li>
                <li>من نحن</li>
            </ul>
            <h2>من نحن</h2>
        </div>
    </div>
</div> -->

<div class="new-comers-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-content">
                    <span class="d-inline-block px-4 py-2 rounded mb-3" style="
                            background-color: rgba(33, 102, 158, 0.05);
                            color: #21669e;
                        ">
                        من نحن
                    </span>
                    <h1>{{aboutUsService.data().firstSectionTitle}}</h1>
                    <p>
                        {{aboutUsService.data().firstSectionDescription}}
                    </p>
                    <ul>
                        <li>
                            <i class="bi bi-pencil"></i>
                            <div>
                                <h5> {{aboutUsService.data().pointOneTitle}}</h5>
                                <p>
                                    حيث نجمع بين المعرفة الأكاديمية والمهارات
                                    العملية لإعداد قادة المستقبل
                                </p>

                            </div>
                        </li>

                        <li>
                            <i class="bi bi-pencil"></i>
                            <div>
                                <h5>{{aboutUsService.data().pointTwoTitle}}</h5>
                                <p>
                                    نسعى لتوفير بيئة محفزة تدعم تنوع الأفكار
                                    والمواهب
                                </p>
                            </div>
                        </li>
                        <li>
                            <i class="bi bi-pencil"></i>
                            <div>
                                <h5>{{aboutUsService.data().pointThreeTitle}}</h5>
                                <p>
                                    رؤيتنا هي تقديم تعليم متكامل يرتقي بالطلبة
                                    والمجتمع نحو مستقبل أفضل
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-image">
                    <img [src]="firstPartOfPathForImages+aboutUsService.data().firstSectionImage" alt="image"
                        class="img-fluid uniform-img" />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="new-comers-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-image">
                    <img [src]="firstPartOfPathForImages+aboutUsService.data().visionAndMessageSectionImage"  alt="image"
                        class="img-fluid uniform-img" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-content">
                    <span class="d-inline-block px-4 py-2 rounded mb-3" style="
                            background-color: rgba(33, 102, 158, 0.05);
                            color: #21669e;
                        ">
                        الرؤية والرسالة
                    </span>
                    <h1>{{aboutUsService.data().visionAndMessageSectionTitle}}</h1>
                    <p>
                        {{aboutUsService.data().visionAndMessageSectionDescription}}
                    </p>
                    <ul>
                        <li>
                            <h5>الرؤية:</h5>
                            <p>
                                {{aboutUsService.data().visionDescription}}
                            </p>
                        </li>
                        <li>
                            <h5>الرسالة:</h5>
                            <p>
                                {{aboutUsService.data().messageDescription}}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="new-comers-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-content">
                    <span class="d-inline-block px-4 py-2 rounded mb-3" style="
                            background-color: rgba(33, 102, 158, 0.05);
                            color: #21669e;
                        ">
                        أهداف الجامعة
                    </span>
                    <h1>أهداف الجامعة</h1>
                    <p>
                        تسعى جامعة الإسراء إلى تحقيق مجموعة من الأهداف
                        الاستراتيجية التي تركز على تطوير التعليم والبحث العلمي
                        وخدمة المجتمع. من خلال
                    </p>
                    <ul>
                        <li>
                            <h5>الأهداف:</h5>
                            <ol>
                                <li>
                                    تعزيز منظومة القيم الأخلاقية والوطنية في
                                    المجتمع الفلسطيني.
                                </li>
                                <li>
                                    تطوير البرامج الأكاديمية بشكل مستمر بما
                                    يراعي معايير الجودة العالمية.
                                </li>
                                <li>
                                    تنمية البحث العلمي عالي الجودة؛ للمساهمة في
                                    تحقيق أهداف المجتمع
                                </li>
                                <li>
                                    تدعيم الشراكة الأكاديمية مع الجامعات المحلية
                                    والإقليمية والعالمية من خلال الانضمام
                                    لاتحادات الجامعات العربية والعالمية.
                                </li>
                                <li>
                                    تعزيز الشراكة بين الجامعة والمؤسسات المختلفة
                                    لتنمية المجتمع.
                                </li>
                                <li>
                                    إعداد خريجين متميزين قادرين على تلبية
                                    احتياجات سوق العمل.
                                </li>
                            </ol>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-image">
                    <img src="assets/israaImg/9e2f108f5b71495cdbbf883ba432eaf3.png" alt="image" />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="new-comers-area ptb-100 grey-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="new-comers-content text-center">
                    <span class="d-inline-block px-4 py-2 rounded mb-3" style="
                            background-color: rgba(33, 102, 158, 0.05);
                            color: #21669e;
                        ">
                        اعتمادات الجامعة
                    </span>
                    <h1>الاعتماد الأكاديمي والجوائز</h1>
                    <p>
                        حصلت جامعة الإسراء على العديد من الاعتمادات الأكاديمية
                        الوطنية والدولية التي تؤكد التزامها بأعلى معايير الجودة
                        في التعليم. نحن فخورون بإنجازاتنا العديدة
                    </p>
                    <app-certificate />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="instructor-area pt-100 pb-70">
    <div class="container">
        <span class="d-inline-block px-4 py-2 rounded mb-3"
            style="background-color: rgba(33, 102, 158, 0.05); color: #21669e">
            صور الجامعة
        </span>
        <div class="section-title text-start">
            <h2>تصفح صور الجامعة</h2>
        </div>
        <app-instructors-style-two></app-instructors-style-two>
    </div>
</div>