<div class="instructor-slides">
    <owl-carousel-o [options]="instructorSlides">
        <ng-template carouselSlide>
            <div class="single-instructor-box mb-30">
                <div class="image">
                    <img
                        src="assets/cert/6ab534c27b119a9745f730539b85e5c7.jpeg"
                        alt="image"
                        class="img-fluid uniform-img"
                    />
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-instructor-box mb-30">
                <div class="image">
                    <img
                        src="assets/cert/05d70abdfbd0482a37aaf5feaed7daa4.jpeg"
                        alt="image"
                        class="img-fluid uniform-img"
                    />
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-instructor-box mb-30">
                <div class="image">
                    <img
                        src="assets/cert/50dd3262aa1be3265e7ffd4bdf8d9c25.jpeg"
                        alt="image"
                        class="img-fluid uniform-img"
                    />
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-instructor-box mb-30">
                <div class="image">
                    <img
                        src="assets/cert/8b0282f98e1adf17406bd18a37fa9246.jpeg"
                        alt="image"
                        class="img-fluid uniform-img"
                    />
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
