<app-header />

<div class="page-title-area item-bg1" [style.background-image]="coverImage">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">الرئيسية</a></li>
                <li>ألبومات الصور</li>
            </ul>
            <h2>ألبومات الصور</h2>
        </div>
    </div>
</div>

<div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div
                class="col-lg-4 col-md-6 col-sm-6"
                *ngFor="let gallery of galleries"
            >
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img
                            [src]="baseUrl + gallery?.imageString"
                            alt="images"
                        />
                    </div>
                    <div class="member-content">
                        <h3>
                            <a>{{ gallery?.title }}</a>
                        </h3>
                        <!-- <span>{{gallery?.category?.title}}</span> -->
                        <ul class="social">
                            <li>
                                <a (click)="navigateTOGallery(gallery.id)"
                                    >مشاهدة الالبوم</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
