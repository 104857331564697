import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class GalleryService {
    baseUrl = environment.API_URL + "Galleries/";
    constructor(private _http: HttpClient) {}

    getForHomePage(): Observable<any> {
        return this._http.get<any>(this.baseUrl + "GetForHomePage");
    }

    get(id: number) {
        return this._http.get<any>(`${this.baseUrl}${id}`);
    }

    getListByCategoryTypeByCategoryType(categoryType: number) {
        let query = '?';
    if (categoryType) query += `&categoryType=${categoryType}`;
        return this._http.get<any>(`${this.baseUrl}GetListByCategoryTypeByCategoryType${query}`);
    }
}
