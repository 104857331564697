import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-president-speech",
    templateUrl: "./president-speech-page.component.html",
    styleUrls: ["./president-speech-page.component.scss"],
})
export class PresidentSpeechPageComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
