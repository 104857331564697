<footer class="footer-area">
    <div class="container">
        <div class="logo">
            <a routerLink="/">
                <img src="assets/israaImg/FooterAndDashboard.png" alt="israa university logo" />
            </a>
        </div>
        <div class="row d-flex ">
            <div class="col-lg-4 col-md-6">
                <div class="single-footer-widget">
                    <h3>روابط رئيسية</h3>
                    <ul class="footer-links d-flex">
                        <div class="col-6">
                            <li><a routerLink="/">الرئيسية</a></li>
                            <li><a routerLink="/">الجامعة</a></li>
                            <li><a routerLink="/">الشؤون الإدارية</a></li>
                        </div>

                        <div class="col-6">
                            <li><a routerLink="/">الكليات</a></li>
                            <li><a routerLink="/">البحث العلمي</a></li>
                            <li><a routerLink="/">الشؤون الأكاديمية</a></li>
                        </div>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-footer-widget ms-5">
                    <h3>اقسام الجامعة</h3>
                    <ul class="footer-links  d-flex">
                        <div class="col-6">
                            <li><a routerLink="/">مراكز الاقسام</a></li>
                            <li><a routerLink="/">القبول والتسجيل</a></li>
                            <li><a routerLink="/">وحدات ومراكز</a></li>
                        </div>
                        <div class="col-6">
                            <li><a routerLink="/">أقسام الجامعة</a></li>
                            <li><a routerLink="/">العلاقات العامة</a></li>
                        </div>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-footer-widget float-end">
                    <h3>تواصل معنا</h3>
                    <ul class="contact-links">
                        <li><a href="mailto:public@israa.edu.ps">
                            <i class="bi bi-envelope-fill p-2"></i>public@israa.edu.ps</a></li>
                        <li><a href="tel:+502464675"><i class="bi bi-telephone-fill p-2"></i>464675 502+</a></li>
                        <li><span><i class="bi bi-geo-alt-fill p-2"></i>غزة - مدينة الزهراء</span></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="footer-bottom-area">
            <hr class="footer-divider top-divider">
            <ul class="social-links">
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-telegram"></i></a></li>
            </ul>
            <hr class="footer-divider bottom-divider">
        </div>

        <p class="copyright">© جميع الحقوق محفوظة لدى جامعة الاسراء 2024</p>
    </div>
</footer>