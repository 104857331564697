import { Component, HostListener, Input } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { LinkService } from "src/app/_services/link.service";
import { PageService } from "src/app/_services/page.service";

@Component({
    selector: "app-header-style-one",
    templateUrl: "./header-style-one.component.html",
    styleUrls: ["./header-style-one.component.scss"],
})
export class HeaderStyleOneComponent {
    @Input() entityId: number | null = null;
    mainTitleInHeader: any[] = [];

    isSticky: boolean = false;
    @HostListener("window:scroll", ["$event"])
    checkScroll() {
        const scrollPosition =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    constructor(
        private _linkService: LinkService,
        private _pageService: PageService,
        private _router: Router
    ) {}

    ngOnInit(): void {
        this._linkService
            .getLinkForHomePageHeaderNoParent(this.entityId)
            .subscribe({
                next: (res) => {
                    this.mainTitleInHeader = res;
                },
            });
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

    navigateTopage(link: any) {

        if (link.pageId) {
            this._pageService.get(link.pageId).subscribe({
                next: (res) => {

                    const navigationExtras: NavigationExtras = { state: res };
                    this._router.navigate(
                        ["pages/", link.pageId],
                        navigationExtras
                    );
                },
            });
        } else if (link.sectionId) {
            //   this._sectionService.get(link.sectionId).subscribe({
            //     next: res => {
            //       this._router.navigate(['colleges/', link.sectionId]);
            //     },
            //   });
        } else if (link.href) {
            this._router.navigate([link.href]);
        }
    }

    isActive(header: any): boolean {
        const currentUrl = this._router.url;
        return currentUrl.startsWith(header.link);
    }
}
