<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img [src]=" baseUrlHub+websettingService.webSetting()?.mainAboutUsImage || 'assets/img/about/about1.jpg'"
                        height="585" width="600" class="shadow" alt="image">
                    <img src="assets/img/about/about2.jpg" class="shadow" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">حول الجامعة</span>
                    <div [innerHTML]="websettingService.webSetting()?.aboutUs"></div>
                    <a routerLink="/courses-2-columns-style-1" class="default-btn"><i
                            class='bx bx-move-horizontal icon-arrow before'></i><span class="label">عرض حول
                            الجامعة</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>