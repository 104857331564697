<app-header />

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Events</li>
            </ul>
            <h2>Events Details</h2>
        </div>
    </div>
</div>

<div class="events-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="events-details-header">
                    <a routerLink="/events" class="back-all-events"
                        ><i class="bx bx-chevrons-left"></i> Back To All
                        Events</a
                    >
                    <h3>NZ Education Fair 2020 in USA</h3>
                    <ul class="events-info-meta d-none">
                        <li>
                            <i class="flaticon-timetable"></i> 10 November, 2020
                        </li>
                        <li><i class="far fa-clock"></i> 10.00AM - 10.00PM</li>
                    </ul>
                    <div class="events-meta">
                        <ul>
                            <li>
                                <i class="bx bx-folder-open"></i>
                                <span>Category</span>
                                <a href="#">Design</a>
                            </li>
                            <li>
                                <i class="bx bx-group"></i>
                                <span>Date</span>
                                10 November, 2020
                            </li>
                            <li>
                                <i class="bx bx-calendar"></i>
                                <span>Time</span>
                                10.00AM - 10.00PM
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="events-details">
                    <div class="events-details-image">
                        <img src="assets/img/events/events1.jpg" alt="image" />
                    </div>
                    <div class="events-details-desc">
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged.
                        </p>
                        <p>
                            It was popularised in the 1960s with the release of
                            Letraset sheets containing Lorem Ipsum passages, and
                            more recently with desktop publishing software like
                            Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <blockquote class="wp-block-quote">
                            <p>
                                It is a long established fact that a reader will
                                be distracted by the readable content of a page
                                when looking at its layout.
                            </p>
                            <cite>Tom Cruise</cite>
                        </blockquote>
                        <p>
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout. The point of using Lorem
                            Ipsum is that it has a more-or-less normal
                            distribution of letters, as opposed to using
                            'Content here, content here', making it look like
                            readable English.
                        </p>
                        <p>
                            Many desktop publishing packages and web page
                            editors now use Lorem Ipsum as their default model
                            text, and a search for 'lorem ipsum' will uncover
                            many web sites still in their infancy. Various
                            versions have evolved over the years, sometimes by
                            accident, sometimes on purpose (injected humour and
                            the like) making it look like readable English.
                        </p>
                    </div>
                    <div class="events-info-links">
                        <a href="#" target="_blank">+ Google Calendar</a>
                        <a href="#" target="_blank">+ iCal Export</a>
                    </div>
                    <div class="ednuv-post-navigation">
                        <div class="prev-link-wrapper">
                            <div class="info-prev-link-wrapper">
                                <a routerLink="/single-events">
                                    <span class="image-prev">
                                        <img
                                            src="assets/img/events/events2.jpg"
                                            alt="image"
                                        />
                                        <span class="post-nav-title">Prev</span>
                                    </span>
                                    <span class="prev-link-info-wrapper">
                                        <span class="prev-title"
                                            >Don't buy a tech gift until you
                                            read these rules</span
                                        >
                                        <span class="meta-wrapper">
                                            <span class="date-post"
                                                >January 21, 2020</span
                                            >
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="next-link-wrapper">
                            <div class="info-next-link-wrapper">
                                <a routerLink="/single-events">
                                    <span class="next-link-info-wrapper">
                                        <span class="next-title"
                                            >The golden rule of buying a phone
                                            as a gift</span
                                        >
                                        <span class="meta-wrapper">
                                            <span class="date-post"
                                                >January 21, 2020</span
                                            >
                                        </span>
                                    </span>
                                    <span class="image-next">
                                        <img
                                            src="assets/img/events/events3.jpg"
                                            alt="image"
                                        />
                                        <span class="post-nav-title">Next</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_events_details">
                        <h3 class="widget-title">Details</h3>
                        <ul>
                            <li>
                                <span>Start:</span> December 15, 2020 8:00AM
                            </li>
                            <li><span>End:</span> December 19, 2020 8:00AM</li>
                            <li>
                                <span>Events Category:</span>
                                <a routerLink="/events">Design</a>
                            </li>
                        </ul>
                    </div>
                    <div class="widget widget_events_details">
                        <h3 class="widget-title">Organizer</h3>
                        <ul>
                            <li>
                                <span>Organizer Name:</span>
                                <a routerLink="/single-events">Aston King</a>
                            </li>
                            <li>
                                <span>Phone:</span>
                                <a href="tel:+1518 285679">+1 518 285679</a>
                            </li>
                            <li>
                                <span>Email:</span>
                                <a href="mailto:hello@ednuv.com"
                                    >hello@ednuv.com</a
                                >
                            </li>
                            <li>
                                <span>Website:</span>
                                <a href="#" target="_blank"
                                    >http://www.ednuv.com</a
                                >
                            </li>
                        </ul>
                    </div>
                    <div class="widget widget_events_details">
                        <h3 class="widget-title">Venue</h3>
                        <ul>
                            <li>
                                <a href="#" target="_blank"
                                    >27 Division St, New York, NY 10002, USA</a
                                >
                            </li>
                            <li>
                                <a href="#" target="_blank">+ Google Map</a>
                            </li>
                        </ul>
                    </div>
                    <div class="widget widget_instagram">
                        <h3 class="widget-title">Instagram</h3>
                        <ul>
                            <li>
                                <a href="#" target="_blank" class="d-block"
                                    ><img
                                        src="assets/img/blog/blog1.jpg"
                                        alt="image"
                                /></a>
                            </li>
                            <li>
                                <a href="#" target="_blank" class="d-block"
                                    ><img
                                        src="assets/img/blog/blog2.jpg"
                                        alt="image"
                                /></a>
                            </li>
                            <li>
                                <a href="#" target="_blank" class="d-block"
                                    ><img
                                        src="assets/img/blog/blog3.jpg"
                                        alt="image"
                                /></a>
                            </li>
                            <li>
                                <a href="#" target="_blank" class="d-block"
                                    ><img
                                        src="assets/img/blog/blog4.jpg"
                                        alt="image"
                                /></a>
                            </li>
                            <li>
                                <a href="#" target="_blank" class="d-block"
                                    ><img
                                        src="assets/img/blog/blog5.jpg"
                                        alt="image"
                                /></a>
                            </li>
                            <li>
                                <a href="#" target="_blank" class="d-block"
                                    ><img
                                        src="assets/img/blog/blog6.jpg"
                                        alt="image"
                                /></a>
                            </li>
                        </ul>
                    </div>
                    <div class="widget widget_contact">
                        <div class="text">
                            <div class="icon">
                                <i class="bx bx-phone-call"></i>
                            </div>
                            <span>Emergency</span>
                            <a href="tel:+098798768753">+0987-9876-8753</a>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</div>
