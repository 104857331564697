import { Component, OnInit } from "@angular/core";
import { SafeStyle } from "@angular/platform-browser";
import { OwlOptions } from "ngx-owl-carousel-o";
import { AdvertisementService } from "src/app/_services/advertisement.service";

@Component({
    selector: "app-ads",
    templateUrl: "./ads.component.html",
    styleUrls: ["./ads.component.scss"],
})
export class AdsComponent implements OnInit {
    ads: any;
    backgroundImage: SafeStyle;
    constructor(private _adService: AdvertisementService) {}

    ngOnInit(): void {
        this._adService.getForHomePage().subscribe((res) => {

            this.ads = res.response.data;
        });
    }

    adsSlides: OwlOptions = {
        loop: true,
        nav: true,
        rtl: true,
        dots: false,
        autoplayHoverPause: false,
        autoplay: false,
        items: 1,
        navText: [
            "<i class='bx bx-right-arrow-alt'></i>",
            "<i class='bx bx-left-arrow-alt'></i>",
        ],
    };
}