import { Component } from '@angular/core';

@Component({
  selector: 'app-electronic-services',
  templateUrl: './electronic-services.component.html',
  styleUrls: ['./electronic-services.component.scss']
})
export class ElectronicServicesComponent {

}
