<div class="countdown-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="countdown-content">
                    <h2>انضم معنا ! وكن أول الملتحقين بجامعة الاسراء</h2>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="countdown-timer text-center">
                    <div id="timer">
                        <div id="days">23 <span>أيام</span></div>
                        <div id="hours">20 <span>ساعات</span></div>
                        <div id="minutes">60 <span>دقائق</span></div>
                        <div id="seconds">12 <span>ثواني</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>