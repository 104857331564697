import { Component, OnInit } from "@angular/core";
import { AdvertisementService } from "src/app/_services/advertisement.service";
import { PostService } from "src/app/_services/post.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-hometwo-courses",
    templateUrl: "./hometwo-courses.component.html",
    styleUrls: ["./hometwo-courses.component.scss"],
})
export class HometwoCoursesComponent implements OnInit {
    posts: any;
    constructor(private _postService: PostService) {}
    baseUrlHub = environment.API_URL_HUB;

    ngOnInit(): void {
        this.getLatestPosts();
    }

    // for tab click event
    currentTab = "tab1";
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }

    getLatestPosts() {
        // 1 is categoryType.POST
        this._postService.getForHomePage(1).subscribe((res) => {
            this.posts = res.response;
        });
    }
}
