import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SliderService {
  baseUrl = environment. API_URL+ "Sliders/";
  constructor(private _http:HttpClient) { }

  getForHomePage():Observable<any>{
   return this._http.get<any>(this.baseUrl+'GetForHomePage');
  }  
}
