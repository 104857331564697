import { Component, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { PartnerService } from "src/app/_services/partner.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-partner-style-one",
    templateUrl: "./partner-style-one.component.html",
    styleUrls: ["./partner-style-one.component.scss"],
})
export class PartnerStyleOneComponent implements OnInit {
    partners: any;
    baseUrlHub = environment.API_URL_HUB;

    constructor(private _partnerService: PartnerService) {}

    ngOnInit(): void {
        this.getAllPartners();
    }

    partnerSlides: OwlOptions = {
        loop: true,
        nav: false,
        dots: false,
        rtl: true,
        autoplayHoverPause: true,
        autoplay: false,
        margin: 30,
        navText: [
            "<i class='bx bx-right-arrow-alt'></i>",
            "<i class='bx bx-left-arrow-alt'></i>",
        ],
        responsive: {
            0: {
                items: 2,
            },
            576: {
                items: 3,
            },
            768: {
                items: 4,
            },
            1200: {
                items: 6,
            },
        },
    };

    getAllPartners() {
        this._partnerService.getForHomePage().subscribe({
            next: (res) => {

                this.partners = res;
            },
        });
    }
}
