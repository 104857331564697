import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CarouselModule } from "ngx-owl-carousel-o";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CountUpModule } from "ngx-countup";
import { NgxScrollTopModule } from "ngx-scrolltop";
import { LightboxModule } from "ngx-lightbox";
import { FormsModule } from "@angular/forms";
import { LightgalleryModule } from "lightgallery/angular";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomePageTwoComponent } from "./components/pages/home-page-two/home-page-two.component";
import { FooterComponent } from "./components/common/footer/footer.component";
import { CategoriesStyleOneComponent } from "./components/common/categories-style-one/categories-style-one.component";
import { PartnerStyleOneComponent } from "./components/common/partner-style-one/partner-style-one.component";
import { HeaderStyleTwoComponent } from "./components/common/header-style-two/header-style-two.component";
import { HometwoMainBannerComponent } from "./components/pages/home-page-two/hometwo-main-banner/hometwo-main-banner.component";
import { HometwoAboutComponent } from "./components/pages/home-page-two/hometwo-about/hometwo-about.component";
import { HometwoCoursesComponent } from "./components/pages/home-page-two/hometwo-courses/hometwo-courses.component";
import { AdsComponent } from "./components/common/ads/ads.component";
import { InstructorsStyleTwoComponent } from "./components/common/instructors-style-two/instructors-style-two.component";
import { WebinarCountdownComponent } from "./components/common/webinar-countdown/webinar-countdown.component";
import { HeaderStyleThreeComponent } from "./components/common/header-style-three/header-style-three.component";
import { CategoriesStyleThreeComponent } from "./components/common/categories-style-three/categories-style-three.component";
import { InstructorsPageOneComponent } from "./components/pages/instructors-page-one/instructors-page-one.component";
import { GalleryPageComponent } from "./components/pages/gallery-page/gallery-page.component";
import { EventsPageComponent } from "./components/pages/events-page/events-page.component";
import { EventsDetailsPageComponent } from "./components/pages/events-details-page/events-details-page.component";
import { ErrorPageComponent } from "./components/pages/error-page/error-page.component";
import { CoursesDetailsPageComponent } from "./components/pages/courses-details-page/courses-details-page.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AllNewsComponent } from "./components/pages/all-news/all-news.component";
import { NgxPaginationModule } from "ngx-pagination";
import { ActivitySectionComponent } from "./components/pages/home-page-two/activity-section/activity-section.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { LoadingInterceptor } from "./_services/loading.interceptor";
import { PagesComponent } from "./components/pages/pages/pages.component";
import { HeaderStyleOneComponent } from "./components/common/header-style-one/header-style-one.component";
import { ElectronicServicesComponent } from "./components/pages/electronic-services/electronic-services.component";
import { AboutUsPageComponent } from "./components/pages/about-us-page/about-us-page.component";
import { PresidentSpeechPageComponent } from "./components/pages/president-speech-page/president-speech-page.component";
import { HeaderComponent } from "./components/common/header/header.component";
import { CertificateComponent } from "./components/pages/about-us-page/certificate/certificate.component";

@NgModule({
    declarations: [
        AppComponent,
        HomePageTwoComponent,
        FooterComponent,
        CategoriesStyleOneComponent,
        PartnerStyleOneComponent,
        HeaderStyleTwoComponent,
        HometwoMainBannerComponent,
        HometwoAboutComponent,
        HometwoCoursesComponent,
        AdsComponent,
        InstructorsStyleTwoComponent,
        WebinarCountdownComponent,
        HeaderStyleThreeComponent,
        CategoriesStyleThreeComponent,
        InstructorsPageOneComponent,
        GalleryPageComponent,
        EventsPageComponent,
        EventsDetailsPageComponent,
        ErrorPageComponent,
        CoursesDetailsPageComponent,
        AllNewsComponent,
        ActivitySectionComponent,
        PagesComponent,
        HeaderStyleOneComponent,
        ElectronicServicesComponent,
        AboutUsPageComponent,
        PresidentSpeechPageComponent,
        HeaderComponent,
        CertificateComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CarouselModule,
        CountUpModule,
        NgxScrollTopModule,
        LightboxModule,
        FormsModule,
        LightgalleryModule,
        HttpClientModule,
        NgxPaginationModule,
        NgxSpinnerModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
