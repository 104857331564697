import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LinkService {
  baseUrl = environment. API_URL+ "Links/";
  constructor(private _http:HttpClient) { }

  getAllLinks(id:number):Observable<any>{
   return this._http.get<any>(`${this.baseUrl}/${id}`);
  }  

  getLinkForHomePageHeaderNoParent(entityId?: number, sectionId?: number):Observable<any>{
    return this._http.get<any>(`${this.baseUrl}GetLinkForHomePageHeaderNoParent`);
  }
}
