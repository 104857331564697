import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AboutUsService {
    baseUrl = `${environment.API_URL_NEW}about-us-page/`;
    #data = signal<any>({});
    data = this.#data.asReadonly();

    private http = inject(HttpClient);

    constructor() {
        this.get().pipe(takeUntilDestroyed()).subscribe(res => 
            this.#data.update(() => res)
        );
    }

    get(): Observable<any> {
        const headers = new HttpHeaders().set('Accept-Language', 'ar');
        return this.http.get<any>(`${this.baseUrl}localized`, { headers });
    } 
   
}
