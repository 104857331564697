import { Component, OnInit } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { GalleryService } from "src/app/_services/gallery.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-instructors-style-two",
    templateUrl: "./instructors-style-two.component.html",
    styleUrls: ["./instructors-style-two.component.scss"],
})
export class InstructorsStyleTwoComponent implements OnInit {
    baseUrlHub = environment.API_URL_HUB;
    galleries: any;

    constructor(
        private _galleryService: GalleryService,
        private _router: Router
    ) {}

    ngOnInit(): void {
        this.getLatestGalleries();
    }

    getLatestGalleries() {
        this._galleryService.getForHomePage().subscribe((res) => {
            this.galleries = res.response;
            
        });
    }

    navigateTOGallery(id: number) {
              this._galleryService.get(id).subscribe((res) => {
            const navigationExtras: NavigationExtras = { state: res.response };
            this._router.navigate(["galleries/", id], navigationExtras);
        });
    }
}
